<template>
  <b-card title="On Boarded Business List">

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group class="justify-content-end">
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
        styleClass="vgt-table striped condensed no-outline"
    >

      <template
          slot="table-row"
          slot-scope="props"
      >

        <!-- Column: Name -->
        <span
            v-if="props.column.field === 'status'"
            class="text-nowrap"
        >
          <span @click="updateStatus(props.row)" style="cursor:pointer">
            <span v-if="props.row.status==true"><b-badge pill variant="light-success">Active</b-badge></span>
            <span v-else> <b-badge pill variant="light-danger"> Inactive</b-badge></span>
          </span>
        </span>
        <span v-else-if="props.column.field === 'logo'">
          <b-avatar thumbnail fluid :src="props.row.logo" alt="Image 1"/>
        </span>
        <span v-else-if="props.column.field === 'created_at'">
         <span>{{ props.row.created_at.split('T')[0] }}</span>
        </span>
        <span v-else-if="props.column.field === 'index'">
         <span>{{ props.row.originalIndex + 1 }}</span>
        </span>
        <span v-else-if="props.column.field === 'business_type'">
         <span>{{ props.row.business_type.name }}</span>
        </span>
        <span
            v-else-if="props.column.field === 'featured'"
            class="text-nowrap"
        >
            <span @click="updateFeaturedStatus(props.row.id)" style="cursor:pointer">
              <span v-if="props.row.featured==true"><b-badge pill variant="light-success">Active</b-badge></span>
              <span v-else> <b-badge pill variant="light-danger"> Inactive</b-badge></span>
            </span>
        </span>
        <span
            v-else-if="props.column.field === 'business_status'"
            class="text-nowrap"
        >
            <span @click="updateStatus(props.row.id)" style="cursor:pointer">
              <span v-if="props.row.business_status==true"><b-badge pill variant="light-success">Active</b-badge></span>
              <span v-else> <b-badge pill variant="light-danger"> Inactive</b-badge></span>
            </span>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
          <span>
              <b-button @click="edit(props.row)"
                        v-b-modal.modal-prevent-closing
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="primary" class="btn-icon rounded-circle mr-1"
                        v-if="showEdit"
              >
                <feather-icon
                    icon="Edit2Icon"
                />
              </b-button>
             <b-button @click="deleteType(props.row)"
                       id="delete-btn"
                       v-b-modal.modal-delete
                       v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                       variant="primary" class="btn-icon rounded-circle"
                       v-if="showDelete"
             >
                <feather-icon
                    icon="TrashIcon"
                />
              </b-button>
          </span>
          </span>
        </span>

        <!-- Column: Status -->

        <!-- Column: Action -->

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
        id="modal-delete"
        ref="modal-delete"
        centered
        modal-class="modal-danger"
        title="Are you sure?"
    >
      <b-card-text class="border-2">
        <span>If you delete, you won't be able to revert this action!!</span>
      </b-card-text>

      <template #modal-footer>
        <div class="w-100">


          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="float-right"
              @click="confirmDelete()"
          >
            Accept
          </b-button>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="float-right mr-1"
              @click="$refs['modal-delete'].toggle('#delete-btn')"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>

  </b-card>

</template>

<script>

import {

  BFormFile,
  BCol,
  BFormTextarea,
  BForm,
  BFormRating,
  BFormInvalidFeedback,
  BInputGroup,
  BInputGroupPrepend,
  BFormValidFeedback,
  BImg, BContainer,
  BAvatar,
  BButton,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BModal,
  VBModal,
  BCardText
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    VueGoodTable,
    BAvatar,
    BCard,
    BBadge,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BCardText,
    BFormFile,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BFormRating,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    BFormValidFeedback,
    BImg, BContainer,
    vSelect
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  computed: {

    ...mapState('business', ["businesses", "businessError", "businessLoading"]),

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  watch: {

    businesses(newValue, oldValue) {
      if (newValue) {
        this.rows = this.businesses
      }
    }
  },
  data() {
    return {
      nameState: null,
      addNewMode: false,
      popupActive: true,
      modify: {
        id:null,
      },
      pageLength: 5,
      dir: false,
      columns: [

        {
          label: '#',
          field: 'index',
          type: 'number',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Name',
          field: 'business_name',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Slug',
          field: 'business_slug',
          tdClass: 'text-center',
          thClass: 'text-center'
        },
        {
          label: 'Type',
          field: 'business_type',
          tdClass: 'text-center',
          thClass: 'text-center'
        },

        {
          label: 'Featured',
          field: 'featured',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
        },

        {
          label: 'Onboarded By',
          field: 'onboarded_by',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
        },

      ],
      methodId: null,
      showAdd: true,
      showEdit: false,
      showDelete: true,
      rows: [],
      searchTerm: '',
      product_image1: null,
      product_image_id1: undefined,
    }
  },
  beforeCreate() {

  },
  created() {
    this.$store.dispatch('business/fetchBusiness').then(result => {
      this.rows = this.businesses

    })
    if(this.showEdit||this.showDelete){
      this.columns.push( {
        label: 'Action',
        field: 'action',
        tdClass: 'text-center',
        thClass:'text-center',
        sortable: false,
      })

    }
  },
  methods: {
    deleteType(data){
      this.modify.id=data.business_slug
    },
    async confirmDelete() {
      await this.$store.dispatch("business/deleteBusiness", {id: this.modify.id}).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Success`,
            icon: 'ThumbsUpIcon',
            variant: 'success',
            text: result,
          },
        })
      })
      this.$nextTick(() => {
        this.$refs['modal-delete'].toggle('#delete-btn')
      })
    },
    updateStatus(data) {
      this.$store.dispatch('business/toggleBusinessStatus', {id: data}).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: result.code === 400 ? `Error` : `Success`,
            icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
            variant: result.code === 400 ? `danger` : 'success',
            text: result.message,
          },
        })
      })
    },
    updateCommissionStatus(data) {
      this.$store.dispatch('business/toggleBusinessCommissionStatus', {id: data}).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: result.code === 400 ? `Error` : `Success`,
            icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
            variant: result.code === 400 ? `danger` : 'success',
            text: result.message,
          },
        })
      })
    },
    updateFeaturedStatus(data) {
      console.log(data)
      this.$store.dispatch('business/toggleBusinessFeatureStatus', {id: data}).then(result => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: result.code === 400 ? `Error` : `Success`,
            icon: result.code === 400 ? `ThumbsDownIcon` : 'ThumbsUpIcon',
            variant: result.code === 400 ? `danger` : 'success',
            text: result.message,
          },
        })
      })
    },


  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
